import React, {useEffect, useRef, useState} from "react"
import IbatApi from "../../utils/IbatApi";

import IconRepIn from "../../images/icon-rep-in.png"
import IconCall from "../../images/icon-call.png"
import IconSms from "../../images/icon-sms.png"
import IconMail from "../../images/icon-mail.png"
import IconRep from "../../images/icon-rep.png"
import IconCallIn from "../../images/icon-call-in.png"
import IconSmsIn from "../../images/icon-sms-in.png"
import IconMailIn from "../../images/icon-mail-in.png"
import MinIconCallIn from "../../images/min-call-in.png"
import MinIconSmsIn from "../../images/min-sms-in.png"
import MinIconMailIn from "../../images/min-mail-in.png"
import MinIconRepIn from "../../images/min-rep-in.png"
import MinIconCall from "../../images/min-call.png"
import MinIconSms from "../../images/min-sms.png"
import MinIconMail from "../../images/min-mail.png"
import MinIconRep from "../../images/min-rep.png"
import ContactIcon from "../../images/contact-illus.png"
import TeamInteraction from "./TeamInteraction";
import constants, {text} from "../util/constants";
import {useSnackbar} from "notistack";
import Checkbox from "../util/Checkbox";
import Modal from "react-responsive-modal";
import {IconButton, Tooltip} from "@mui/material";
import {useMediaQuery} from "react-responsive";


export default function InteractionClient({id,devis,siteId, site, ibatApi}) {
    const [client, setClient] = useState({content: ""})
    const [siteSelected, setSite] = useState(site)
    const [intervention, setIntervention] = useState()
    const [sendSms, setsendSms] = useState()
    const buttonRef = useRef(null)

    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })
    const api = ibatApi ?? new IbatApi()
    useEffect(() => {
        !siteSelected &&
        api.getSite(siteId ?? siteSelected.id)
            .then(resp => {
                setSite(resp)
            })
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        api.getClientInteraction(id,devis)
            .then(data => setClient(client => ({...client, interactions: data.sort((a,b)=>a.dateEmission-b.dateEmission)})))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        api.getIntervention(id)
            .then(data=>setIntervention(data))
            .catch(e => {})
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (buttonRef.current) {
            // console.log(textAreaRef.current.focu)
            buttonRef.current.focus()
        }
    }, [buttonRef.current]);
    const dateOption = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric",}
    const { enqueueSnackbar } = useSnackbar();


    function handleSMS(recipient) {
        let data = handleInteractionData(sendSms)
        saveInteraction(data, recipient)
        setsendSms(false)
    }

    function saveInteraction(data, number) {
        api.addClientInteraction(id, data, devis, number)
            .then(data1 => {
                setClient(client => ({...client, content: "", interactions: data1.devis?data1.devis.interactionClients:data1.interactionClients}))
                enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"})
            })
            .catch(e => {
                if(e.response.status===406) {
                    console.log(e.response)
                    let data1 = e.response.data
                    setClient(client => ({...client, content: "", interactions: data1.devis?data1.devis.interactionClients:data1.interactionClients}))
                    enqueueSnackbar(text.SNACKBAR_ERROR_SMS, {variant: "error"})
                }
                else {
                    enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"})
                }
            })
    }

    function handleInteractionData(e) {
        let time = new Date()
        const data = {
            type: e.target.name,
            dateEmission: time.getTime(),
            content: client.content
        }
        return data
    }

    function handleInteraction(e) {
        let data = handleInteractionData(e)
        if (data.content === "" || !data.content) {
            enqueueSnackbar(text.SNACKBAR_ERROR_EMPTY_MSG,{variant:"error"})
            return
        }

        if (data.type === "sms-out") {
            let gsmNumber = siteSelected.contactPhone.filter(phone => phone.match("^(?:(?:\\+|00)33[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[6-7](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$"))
            if (gsmNumber.length === 0) {
                enqueueSnackbar(text.SNACKBAR_ERROR_NO_PHONE_NUMBER,{variant:"error"})
                return;
            }
            else if (gsmNumber.length > 1) {
                setsendSms(e)
                return;
            }
            saveInteraction(data, gsmNumber[0])
            return;
        }
        saveInteraction(data)
    }

    function handleContent(e) {
        setClient(client => ({...client, content: e.target.value.substring(0, constants.MAX_LENGTH)}))
    }

    function getIcon(type) {
        if (type === "sms-in") return <img alt={""} style={{marginRight: 3}} src={MinIconSmsIn}/>
        if (type === "mail-in") return <img alt={""} style={{marginRight: 3}} src={MinIconMailIn}/>
        if (type === "call-in") return <img alt={""} style={{marginRight: 3}} src={MinIconCallIn}/>
        if (type === "sms-out") return <img alt={""} style={{marginRight: 3}} src={MinIconSms}/>
        if (type === "mail-out") return <img alt={""} style={{marginRight: 3}} src={MinIconMail}/>
        if (type === "call-out") return <img alt={""} style={{marginRight: 3}} src={MinIconCall}/>
        if (type === "voice-out") return <img alt={""} style={{marginRight: 3}} src={MinIconRep}/>
        return <img alt={""} style={{marginRight: 3}} name={""} src={MinIconRepIn}/>
    }

    function handleEmergencyStatus(status){
        api.updateEmergencyEventStatus(id, status)
            .then((resp)=> {
                setIntervention(resp)
                enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"})
            })
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))

    }

    const handlePrioritize = (e) => {
        api.prioritizeIntervention(id, e.target.checked)
            .then(resp => {
            setIntervention(resp)
        })
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    if(!api.hasRole(constants.PERMISSION_AM) && !api.clientToken){
        return <h3>{text.NO_RIGHT}</h3>
    }
    return (
        <div className={`w100 fs1 interaction flex-column`}>

           <div className={"onSitePhone br10 w100 flex-row bord1"}>
               {
                   api.clientToken || devis ?
                       <></>
                       :
                       <Checkbox handleCheckChieldElement={handlePrioritize} isChecked={intervention?.prioritize} className={"flex bord1 label1 al-center"} name={"prioritize"} label={"Intervention à prioriser"} type={"checkbox"}/>
               }
                <h3 className={isMobile ? "w100" : "w25"}>{siteSelected?.name ?? "Sans nom"} : {siteSelected?.contactPhone?.reduce((t1,t2)=>t1+`${t1===""?" ":"; "}` +t2,"")}</h3>
                <h3 className={"w25"}>{siteSelected?.contactMail}</h3>
            </div>
            <div className={"flex-row primary-section interactionWrapper"}>
                <div className={"entree w100 flex-column"}>

                    <div className={"flex-row w100"}>
                        {client.interactions &&
                            <div className={"outputs secondary-section flex-column bord1"}>
                                {client.interactions
                                    .map((it, idex) => <div className={"bord1 message br05"} key={idex}>
                                        <div className={"bord1 label2"}>{getIcon(it.type)}{new Date(it.dateEmission).toLocaleDateString(undefined, dateOption)}</div> {it.content}  </div>)}
                            </div>
                        }
                    </div>
                    {!api.clientToken &&
                        <div className={"flex-row add-entree w100 secondary-section"}>
                            <div className={"grid w65"}>
                                <textarea maxLength={4000} id={"content"} value={client.content} onChange={handleContent}/>
                                <div className={"bori label"}>{client.content?.length ?? 0}/{constants.MAX_LENGTH}</div>
                            </div>
                            <div className={"grid buttons"}>
                                <Tooltip title={"Rapporter un SMS reçu"}>
                                    <button ref={buttonRef} name={"sms"} onClick={handleInteraction}><img alt={"sms-in"} name={"sms-in"}
                                                                                          src={IconSmsIn}/></button>
                                </Tooltip>

                                <Tooltip title={"rapporter un e-mail reçu"}>
                                    <button name={"mail"} onClick={handleInteraction}><img alt={"mail-in"} name={"mail-in"}
                                                                                       src={IconMailIn}/></button>
                                </Tooltip>
                                <Tooltip title={"Rapporter un appel reçu"}>
                                    <button name={"call"} onClick={handleInteraction}><img alt={"call-in"} name={"call-in"}
                                                                                       src={IconCallIn}/></button>
                                </Tooltip>

                                <Tooltip title={"Rapporter un message du client sur notre répondeur"}>
                                <button name={"voice"} onClick={handleInteraction}><img alt={"voice-in"} name={"voice-in"}
                                                                                        src={IconRepIn}/></button>
                                </Tooltip>


                                <Tooltip title={"Envoyer par SMS et rapporter"}>
                                    <button name={"sms"} onClick={handleInteraction}><img alt={"sms-out"} name={"sms-out"}
                                                                                      src={IconSms}/></button>
                                </Tooltip>

                                <Tooltip title={"Rapporter un email envoyé"}>
                                    <button name={"mail"} onClick={handleInteraction}><img alt={"mail-out"} name={"mail-out"}
                                                                                       src={IconMail}/></button>
                                </Tooltip>

                                <Tooltip title={"Rapporter un appel sortant"}>
                                <button name={"call"} onClick={handleInteraction}><img alt={"call-out"} name={"call-out"}
                                                                                       src={IconCall}/></button>
                                </Tooltip>

                                <Tooltip title={"Rapporter un message sur répondeur du client"}>
                                <button name={"voice"} onClick={handleInteraction}><img alt={"voice-out"} name={"voice-out"}
                                                                                        src={IconRep}/></button>
                                </Tooltip>

                            </div>
                        </div>
                    }
                    {
                        api.clientToken || devis ?
                            <></>
                            :
                            <TeamInteraction id={id} teamInteraction={intervention?.teamInteraction}/>

                    }
                </div>
                <div className={"lights flex-column"}>
                    <Tooltip title={"Pas de confirmation reçue"}><div className={`br10 status orange ${intervention?.emergencyEventStatus === "NO_RESPONSE" ? "selected" : ""}`} onClick={()=>handleEmergencyStatus("NO_RESPONSE")}/></Tooltip>
                    <Tooltip title={"A contacter"}><div className={`br10 status purple ${intervention?.emergencyEventStatus === "NOK" ? "selected" : ""}`} onClick={()=>handleEmergencyStatus("NOK")}/></Tooltip>
                    <Tooltip title={"Date d'intervention confirmée par le client"}><div className={`br10 status green ${intervention?.emergencyEventStatus === "OK" ? "selected" : ""}`} onClick={()=>handleEmergencyStatus("OK")}/></Tooltip>
                </div>
            </div>

            <Modal open={sendSms} onClose={() => setsendSms(false)} >
                <div className={"flex-column"}>
                    <h2>Sur quel numéro envoyer le sms ?</h2>

                    {siteSelected?.contactPhone?.filter(phone => phone.match("^(?:(?:\\+|00)33[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[6-7](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$"))?.map((phone, index) => <IconButton key={index} className={"action-btn neutral"} onClick={()=>handleSMS(phone)}>{phone}</IconButton>)}
                </div>
            </Modal>
        </div>
    )
}
