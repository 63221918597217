import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import StepContainer from "../step/StepContainer";
import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from "react";
import constants, {text} from "../components/util/constants";
import {useSnackbar} from "notistack";
import IbatApi from "../utils/IbatApi";
import {v4} from "uuid";
import {timeToDate} from "../helpers/timeToDate";

const Calendar=forwardRef(({begin,end,team,onHover,maps,map, hovered, handleAddPin, child},ref)=>{

    const {enqueueSnackbar}=useSnackbar()
    const [events,setEvents]=useState([])
    const [removed,setRemoved]=useState([])
    const [currentHour, setCurrentHour]=useState(((new Date().getHours()*60 + new Date().getMinutes()) / 14.7 * 10) + 41)
    const api=new IbatApi()
    useImperativeHandle(ref,()=>({
        addEvent(event){
            event.id=event.id || v4()
            setEvents([...events,event])
        },

        save(){
            handleSave()
        },

        getEvents(team,begin,end, updatePath){
            getCalendar(team,begin,end, updatePath)
        },

        travelTime(){
            handleTravelTime()
        }
    }))

    useEffect(()=>{
        const interval = setInterval(()=>{
            setCurrentHour(((10/15)*(new Date().getHours()*60 + new Date().getMinutes()))+new Date().getHours())
        }, 300000);
        return () => clearInterval(interval)
    })

    function handleTravelTime() {
        updateTravelTime(events.sort((e1,e2)=>e1.start-e2.start))

            .then(()=>enqueueSnackbar(text.SNACKBAR_SUCCESS, {variant: "success"}))
            .catch(e=>{
                enqueueSnackbar(text.TRAVEL_TIME_ERROR, {variant: "error"})
            })
    }
    function applyTravelTime(times,origins,destinations){
        let eventsCopy=[...events]
        for (let i = 0; i < times.length; i++) {
            let travelTime=times[i]
            let origin=eventsCopy.find(elem=>elem.id===origins[i].id)
            let destination=eventsCopy.find(elem=>elem.id===destinations[i].id)
            let timeBetweenOriginDestination=(destination.start-origin.endEvent)
            if(timeBetweenOriginDestination<travelTime){
                let deltaTime=origin.endEvent+travelTime-destination.start
                destination.start=destination.start+deltaTime
                destination.endEvent=destination.endEvent+deltaTime
            }
        }
        setEvents(eventsCopy)
    }

    function updateTravelTime(copy){
        let find = copy.find(e=>!e.intervention.devis.site.latitude || !e.intervention.devis.site.longitude);
        //todo attention ajouter dans les traduction file
        if (find){
            alert(`l'intervention sur le site ${find.devis.site.name} pause probleme`)
            return
        }
        let origins=copy.slice(0,copy.length-1)
        let destinations=copy.slice(1)
        return getTimeBetween(origins,destinations)
            .then(times=>applyTravelTime(times,origins,destinations))



    }

    function getTimeBetween(originPoints,destinationPoints){
        if (originPoints.length !== destinationPoints.length) {
            return
        }
        const origin = originPoints.map(originPoint => `${originPoint.intervention.devis.site.latitude},${originPoint.intervention.devis.site.longitude}`)
        const destination = destinationPoints.map(destinationPoint => `${destinationPoint.intervention.devis.site.latitude},${destinationPoint.intervention.devis.site.longitude}`)
        let circuit=[]
        for (let i = 0; i <origin.length; i++) {
            let route=[origin[i],destination[i]]
            circuit.push(route)
        }
        return Promise.all(circuit.map(route=>api.routeTiming(route))).then(result=>result.map(e=>e.route.time*constants.S1))

    }


    function handleSave(){
        events.forEach(evt=>evt.intervention.team=team)
        function getTextEvent(event) {
            return `${event.intervention.devis.site.name} ${timeToDate(event.start)}`
        }
        api.deleteEvent(removed)
            .then(setRemoved([]))
        api.createUpdateEvent(events)
            .then(res=>res.map(event=>enqueueSnackbar(getTextEvent(event),{variant: "error"})))
            .then(e=>enqueueSnackbar(text.SNACKBAR_SAVED,{variant: "success"}))

    }
    function getCalendar(team,begin,end, updatePath){
        setRemoved([])
        api.getPlanningEvent(team,begin,end+constants.H24).then(res=> {
            setEvents(res)
            const path = res.filter(e => constants[e.intervention.status] < 7).sort((a, b) => a.start > b.start ? 1 : -1).map(e=>{
                return {lat:e.intervention.devis.site.latitude, lng:e.intervention.devis.site.longitude}
            })
            updatePath(path)
        })
    }

    function gridData(){
        if(!begin || !end){
            return []
        }
        const daynumber= Math.abs(begin-end)/(constants.H24)

        return new Array(daynumber + 2).fill(1).map((elem, index) => begin + ((index-1) * constants.H24))
    }

    function getDaySpan(event){
        let intvdate=new Date(event.start)
        let startTime=intvdate.getHours()*constants.H1+intvdate.getMinutes()*constants.M1;
        let dayspan=startTime+(event.endEvent-event.start)
        return Math.abs(Math.floor(-dayspan/constants.H24))-1
    }

    function splitIntv(listEvent) {
        let list=[]
        for (let event of listEvent){
            let daySpan=getDaySpan(event)
            let res=[event]
            if(daySpan!==0){
                let eventdate=new Date(event.start)
                let startTime=eventdate.getHours()*constants.H1+eventdate.getMinutes()*constants.M1;
                let firstChild={...event,endEvent:(event.start+(constants.H24-startTime))}
                let midChilds=Array.from({length:daySpan-1},((v, k) => k+1))
                    .map(k=>(
                        {...event,
                            start:event.start-startTime+k*constants.H24,
                            endEvent:event.start-startTime+(k+1)*constants.H24
                        }
                    ))
                let lstChild={...event
                    ,start:event.start-startTime+daySpan*constants.H24,
                    endEvent:event.start-startTime+daySpan*constants.H24+((event.endEvent-event.start)+startTime)%constants.H24}
                res=[firstChild,...midChilds,lstChild]
            }
            list.push(res)
        }
        return list.flat(1)
    }

    function handleTimeChange(eventId,newTime){
        let updatedList =[...events]
        let eventToUpdate = updatedList.find(e=>e.id===eventId);
        eventToUpdate.endEvent=eventToUpdate.start+newTime
        setEvents(updatedList)
    }
    function resetEvent(event){
        let updatedList =[...events]
        let eventToUpdate = updatedList.find(e=>e.id===event.id);
        eventToUpdate.endEvent=event.start+event.duration
        eventToUpdate.start=event.start
        setEvents(updatedList)
    }
    function removeEventFromList(event){
        let list =[...events]
        setEvents(list.filter(e=>e.id!==event))
    }

    function updateIntervention(eventId, date) {
        let updatedList =[...events]
        let i=updatedList.find(evt=>evt.id===eventId)
        let duration=i.endEvent-i.start
        i.start=date
        i.endEvent=date+duration
        setEvents(updatedList)
    }

    function remove(event){
        let eventToRemoveId=events.findIndex(evnt=>evnt.id===event.id);
        let eventToRemove=events.splice(eventToRemoveId,1)
        setRemoved([...removed,...eventToRemove])
        api.deleteEmergencyEventStatus(event.intervention.id)
        // setEvents( events.filter(evnt=>evnt.id!==eventToRemove.id))
    }

    return (
        <Fragment>
            <DndProvider  backend={HTML5Backend} >
                <div className={"grid bg-wh br05 container-list"}>
                    {begin && end && <div className={"hr"} style={{top: currentHour}}/>}
                    {gridData().map(date=>
                        <div className={"grid header br05 flex-column "} key={v4()}>
                            {
                                new Date(parseInt(date))
                                    .toLocaleDateString(undefined,{ weekday: 'long', month: 'numeric', day: 'numeric' ,})
                            }
                        </div>)
                    }
                    {gridData().map((date,index)=>
                        <StepContainer key={date}
                                       isScrollActive={index===0}
                                       beginDate={date}
                                       onTimeChange={handleTimeChange}
                                       resetEvent={resetEvent}
                                       eventList={splitIntv(events)}
                                       onRemove={remove}
                                       onHover={onHover}
                                       hovered={hovered}
                                       handleAddPin={handleAddPin}
                                       removeEventFromList={removeEventFromList}
                                       moveStep={updateIntervention}/>
                    )}
                </div>
            </DndProvider>
            <div style={{position: "absolute", bottom: "0", right: "20px", zIndex: "1"}}>
                {child}
            </div>
        </Fragment>
    )
})
export default Calendar;